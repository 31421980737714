import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {receiveMessage} from '@app/shared/service/socket.service';
import {Events} from '@app/shared/service/events';
import {BehaviorSubject} from 'rxjs';
import {Storage} from '@ionic/storage-angular';
import moment from 'moment';
import 'moment-timezone';
@Injectable()
export class AppSettingsService {
  settings: IAppSettings = {
    delivery: {workingHours: {}, timeFormat: 'h:mm A'},
    stock: {},
    smsProvider: {},
    smsNotification: {},
    customRoles: []
  };
  public settingsSubject = new BehaviorSubject<IAppSettings>(undefined);
  loadingSettings = false;

  constructor(private httpClient: HttpClient, private event: Events, private storage: Storage) {
    this.getAppSettings();
    this.event.subscribe(receiveMessage + ':appSettings', (data) => {
      if (data.action == 'refresh') {
        this.fetchSettingsFromServer();
      }
    });
  }

  async getAppSettings(force?): Promise<IAppSettings> {
    let settings = this.settingsSubject.getValue();
    if (settings != undefined) {
      if (force) {
        this.fetchSettingsFromServer();
      }
      return settings;
    }
    settings = await this.storage.get('appSettings');
    if (settings != undefined) {
      this.settings = settings;
      this.settingsSubject.next(settings);
      this.fetchSettingsFromServer();
      return settings;
    }

    await this.fetchSettingsFromServer();
    return this.settings;
  }

  private async fetchSettingsFromServer() {
    if (this.loadingSettings) {
      return new Promise(resolve => {
        const subscription = this.settingsSubject.subscribe(value => {
          if (value != undefined) {
            setTimeout(() => {
              if (subscription) {
                subscription.unsubscribe();
              }
            });
            resolve(value);
          }
        });
      });
    } else {
      this.loadingSettings = true;
      await this.httpClient.get<IAppSettings>('/app/settings/appSettings')
        .toPromise()
        .then(async settings => {
          if (settings != undefined) {
            await this.storage.set('appSettings', settings);
            this.settings = settings;
            this.settingsSubject.next(settings);
          }
        }).finally(() => {
          this.loadingSettings = false;
        });
    }
  }

  async saveSettings(settings) {
    return this.httpClient.post<IAppSettings>('/app/settings/appSettings', settings).toPromise().then(async newSettings => {
      await this.storage.set('appSettings', newSettings);
      this.settings = newSettings;
      this.settingsSubject.next(newSettings);
    });
  }


  getTimezones() {
    return this.httpClient.get('./assets/data/timezones.json');
  }

  getAccountTime(date) {
    return moment.tz(date, this.settings.timeZone);
  }
  convertAccountTime(date) {
    const systemTime = moment.tz(this.settings.timeZone);
    if (moment().utcOffset() == systemTime.utcOffset() && moment().isDST() == systemTime.isDST()) {
      return moment.tz(date, this.settings.timeZone).startOf('day');
    } else {
      const currentTime = moment(date);
      return systemTime.year(currentTime.year()).dayOfYear(currentTime.dayOfYear()).startOf('day');
    }
  }

}

export interface IAppSettings {
  _id?: string;
  accountId?: string;
  timeZone?: string;
  delivery?: {
    orderServiceTime?: number,
    workingHours?: {
      start?: string,
      end?: string
    },
    distanceMeasurement?: string,
    timeFormat?: string,
    maximumDrivingDistance?: number,
    slotInterval?: number
    calendarMinDays?: number
    calendarMaxDays?: number
    calendarDisable?: boolean
    deliveryDays?: { value: number, checked: boolean }[]
    sendDeliveryNotification?: boolean,
    sendDeliveredNotification?: boolean,
    groupDeliveries?: boolean,
    multiDayRoute?: boolean,
    replaceFixedAddress?: boolean,
    matrixType?: 'time' | 'timeAndDistance',
  };
  stock?: {
    enableStocks?: boolean,
    syncStocks?: boolean
    supplierFromVendor?: boolean
    sendStockNotifications?: boolean
  };
  importOrder?: {
    columnMapping?: any;
  };
  smsProvider?: {
    provider?: string
  };
  smsNotification?: {
    sendDeliveryNotification?: boolean,
    sendDeliveredNotification?: boolean,
    sendNextDeliveryNotification?: boolean,
    deliveryNotification?: string,
    deliveredNotification?: string,
    nextDeliveryNotification?: string,
  };
  customRoles?: {
    roleName: string,
    roleDescription: string,
    authorities: string[]
  }[];
  customFields?: {
    name?: string,
    type?: 'text' | 'number' | 'date'
  }[];
}
